import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAccountDetailsDTO,
  IAccountLoginDTO,
  IAccountLoginResponseDTO,
  IResetPasswordDTO,
} from '../../model/account.model';
import { Observable, catchError, tap, throwError } from 'rxjs';
import {
  LoaderService,
  setLoaderFalse,
} from '../../../../core/services/loader.service';
import { SnackBarService } from '../../../../share/services/utils/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { IHttpErrorResponse } from '../../../../core/model/api/http-response.model';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private snackBar: SnackBarService,
    private translate: TranslateService
  ) {}

  registerAccount(
    accountDTO: IAccountDetailsDTO
  ): Observable<IAccountDetailsDTO> {
    this.loader.setLoader(true);
    return this.http
      .post<IAccountDetailsDTO>('/account/register', accountDTO)
      .pipe(
        setLoaderFalse(this.loader),
        tap((userData: IAccountDetailsDTO) =>
          this.snackBar.openSnackbarSuccess({
            label: this.translate.instant(
              'ACCOUNT.REGISTER.NOTIFICATION.SUCCESS',
              {
                userName: userData.firstName,
              }
            ),
          })
        ),
        catchError((error: IHttpErrorResponse) => {
          if (error.error.message === 'error.userexists') {
            this.snackBar.openSnackbarError({
              label: this.translate.instant('ACCOUNT.ERROR.EMAIL_EXIST'),
            });
          } else {
            this.snackBar.openSnackbarError({
              label: error.error.title ?? '',
            });
          }
          return throwError(() => error);
        })
      );
  }

  resetPasswordInit(email: string) {
    this.loader.setLoader(true);
    return this.http
      .post('/account/reset-password/init', undefined, {
        params: {
          email,
        },
      })
      .pipe(setLoaderFalse(this.loader));
  }

  changePassword(currentPassword: string, newPassword: string) {
    this.loader.setLoader(true);
    return this.http
      .put('/account/change-password', {
        currentPassword,
        newPassword,
      })
      .pipe(setLoaderFalse(this.loader));
  }

  updateAccountData(accountData: IAccountDetailsDTO) {
    this.loader.setLoader(true);
    return this.http
      .put('/account', accountData)
      .pipe(setLoaderFalse(this.loader));
  }

  resetPasswordFinish(passwordDTO: IResetPasswordDTO) {
    this.loader.setLoader(true);
    return this.http
      .post('/account/reset-password/finish', passwordDTO)
      .pipe(setLoaderFalse(this.loader));
  }

  login(loginData: IAccountLoginDTO): Observable<IAccountLoginResponseDTO> {
    this.loader.setLoader(true);
    return this.http
      .post<IAccountLoginResponseDTO>('/authenticate', loginData)
      .pipe(
        setLoaderFalse(this.loader),
        tap(() => this.snackBar.openSnackbarSuccess()),
        catchError((error: IHttpErrorResponse) => {
          if (error.error.status === 401) {
            this.snackBar.openSnackbarError({
              label: this.translate.instant('ACCOUNT.ERROR.BAD_CREDENTIALS'),
            });
          } else {
            this.snackBar.openSnackbarError({
              label: error.error.title ?? '',
            });
          }
          return throwError(() => error);
        })
      );
  }

  getAccount(): Observable<IAccountDetailsDTO> {
    this.loader.setLoader(true);
    return this.http.get<IAccountDetailsDTO>('/account').pipe(
      setLoaderFalse(this.loader),
      catchError((error: IHttpErrorResponse) => {
        this.snackBar.openSnackbarError({
          label: error.error.title ?? '',
        });
        return throwError(() => error);
      })
    );
  }

  activate(key: string) {
    this.loader.setLoader(true);
    return this.http
      .post('/account/activate', undefined, {
        params: {
          key,
        },
      })
      .pipe(setLoaderFalse(this.loader));
  }
}
